var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",style:(_vm.afterIconMarginLeft)},[_c('div',{staticClass:"panel-top-sort"},[_c('span',{staticClass:"sort-img-search"},[_vm._v("Search")]),_vm._t("searchOptions"),_c('input',{staticClass:"sort-btn-search",staticStyle:{"color":"#ffffff"},attrs:{"type":"button","value":_vm.btnSearch,"prepend-icon":"mdi-chevron-down"},on:{"click":_vm.search}})],2),_c('div',{staticClass:"panel-top-total"},[_c('p',{staticClass:"table-total"},[_c('span',{staticClass:"tx-total"},[_vm._v("Total: "),_c('b',[_vm._v(_vm._s(_vm.totalRecords))])])]),_c('p',{staticClass:"table-btns",staticStyle:{"margin":"0px","color":"#FF6D01"}},[_vm._t("subHeaderItem")],2)]),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","fixed-header":true,"height":_vm.tableHeight,"headers":_vm.tableHeaders,"items":_vm.tableItems,"items-per-page":_vm.nowPerPage,"server-items-length":_vm.totalRecords,"item-key":"index","single-select":_vm.singleSelect,"show-select":_vm.showSelect,"checkbox-color":"blue","primary-key":"index","options":_vm.options,"loading":_vm.loading,"loading-text":"Loading...","footer-props":{
        showFirstLastPage: _vm.showFirstLastIcon,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        'items-per-page-options': _vm.pageOptions,
        'items-per-page-text': '',
      },"mobile-breakpoint":0},on:{"input":function($event){return _vm.enterSelect()},"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:`item.index`,fn:function({ item }){return [_c('td',{staticClass:"text-center table-index",attrs:{"title":`${item.index}`}},[_vm._v(" "+_vm._s(item.index)+" ")])]}},_vm._l((_vm.customSlotInfo),function(info){return {key:info.slotName,fn:function({ item }){return [_vm._t(`${info.name}_custom`,null,{"item":item})]}}}),{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.pagination.row-noData'))+" ")]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"deep-orange lighten-2"}})]},proxy:true},{key:`footer.page-text`,fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.pagination.label-page'))+" "),_c('input',{staticClass:"input-page",attrs:{"type":"number","max":_vm.totalPages,"min":"1","disabled":!_vm.showFirstLastIcon},domProps:{"value":_vm.options.page},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.checkInput.apply(null, arguments)}}}),_vm._v(" / "+_vm._s(_vm.totalPages)+" ")]},proxy:true},{key:`footer`,fn:function(item){return [_c('div',{staticClass:"pa-5 pl-2",staticStyle:{"position":"absolute","right":"0","height":"40px","bottom":"8px"},attrs:{"label":"Show Archived"}},[_vm._v(" "+_vm._s(item.props.pagination.itemsLength ? ` ${_vm.$t('common.pagination.label-view')} ${item.props .pagination.pageStart + 1} - ${item.props.pagination.pageStop} / ${item.props.pagination.itemsLength}` : _vm.$t('common.pagination.label-view-noData'))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }